export enum IframeResultEvent {
  SUCCESS = 'success',
  FAILURE = 'failure',
  CANCELLED = 'cancelled',
  IN_PROGRESS = 'inProgress',
}

export enum IframeAction {
  REVOKE = 'revoke',
}

export type IframeResult = {
  event: IframeResultEvent;
  payload?: unknown;
};
