// This utils module will likely export more functions later,
// to avoid having to rewrite import code, disable this rule.
// eslint-disable-next-line import/prefer-default-export
import { v4 as uuid } from 'uuid';

export const isProd = process.env.REACT_APP_STAGE === 'prod' || process.env.REACT_APP_STAGE === 'prod-devnet';

export const isProdMainnet = process.env.REACT_APP_STAGE === 'prod';

export const REVOCATION_PORTAL_FLOW_ID_PREFIX = 'REVOCATION_PORTAL';
export const getFlowId = (): string => `${REVOCATION_PORTAL_FLOW_ID_PREFIX}_${uuid()}`;
