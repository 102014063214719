import React, { useRef, useState } from 'react';
import Loading from './Loading';

export const IFRAME_ID = 'CIVIC_PASS_IFRAME';

export interface PassIframeProperties {
  render: boolean;
  iframeUrl: string;
}

export default function PassIframe({ render, iframeUrl }: PassIframeProperties): JSX.Element {
  const [loading, setLoading] = useState<boolean>(true);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const handleLoad = () => {
    if (iframeRef.current) {
      iframeRef.current.style.visibility = 'visible';
      iframeRef.current.className = 'h-[600px] w-full';
    }
    setLoading(false);
  };
  return (
    <>
      {render && (
        <>
          {loading && (
            <div className="h-[600px] w-full flex">
              <Loading dataTestId="LOADING_BAR" />
            </div>
          )}
          <iframe
            ref={iframeRef}
            title="Civic Pass Revocation"
            src={iframeUrl}
            id={IFRAME_ID}
            data-cy={IFRAME_ID}
            className="h-0 w-full"
            style={{ visibility: 'hidden' }}
            onLoad={handleLoad}
            allow="camera"
            allowFullScreen
          />
        </>
      )}
    </>
  );
}
