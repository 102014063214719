import React from 'react';

interface LoadingProps {
  dataTestId?: string;
}

export default function Loading({ dataTestId }: LoadingProps): JSX.Element {
  return (
    <div className="loading-dots-small-wrap loading-dots-small-wrap w-full flex flex-col items-center full:flex-row full:-space-x-40 justify-center">
      <div className="ignite-dot-pulse" data-testid={dataTestId} />
    </div>
  );
}

Loading.defaultProps = {
  dataTestId: 'LOADING_BAR',
};
