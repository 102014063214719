import React, { useEffect, useState } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { getPortalConfig, PortalConfig } from '../common/config';
import MainContainer from '../common/MainContainer/MainContainer';
import { IframeAction } from '../providers/types';
import PassIframe from '../common/Components/PassIframe';
import { getFlowId } from '../common/util';

export default function UniquenessRevokeScreen(): JSX.Element {
  const [config] = useState<PortalConfig>(getPortalConfig());
  const flowId = getFlowId();
  const flowType = 'revocation';
  const iframeParams = {
    networkAddress: config.gatekeeperNetworkPublicKey,
    action: IframeAction.REVOKE as string,
    ownerSigns: 'true',
    flowId,
    flowType,
  };
  const revokeUrl = `${config.iframeBaseUrl}/?${new URLSearchParams(iframeParams).toString()}`;

  useEffect(() => {
    const handleEvent = (e: MessageEvent) => {
      // Reload the page and restart the flow if the user has successfully revoked their pass and taps the "Done" button
      if (e.data.action === 'revoke' && e.data.event === 'success') {
        window.location.reload();
      }
    };
    window.addEventListener('message', handleEvent);
    return () => {
      window.removeEventListener('message', handleEvent);
    };
  }, []);

  return (
    <MainContainer>
      <div className="flex flex-col items-center full:flex-row full:-space-x-40 justify-center">
        <div className="block max-w-xl rounded-xl bg-white p-6 shadow-lg w-full border-slate-100 border">
          <PassIframe render iframeUrl={revokeUrl} />
        </div>
      </div>
    </MainContainer>
  );
}
