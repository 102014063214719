import * as R from 'ramda';

export type PassType = 'uniqueness';

type PassConfig = {
  gatekeeperNetworkPublicKey: string;
};

type PortalConfigs = {
  passConfigs: Record<PassType, PassConfig>;
  iframeBaseUrl: string;
};

const portalConfigs: Record<string, PortalConfigs> = {
  e2e: {
    iframeBaseUrl: 'http://localhost:3000',
    passConfigs: {
      uniqueness: {
        gatekeeperNetworkPublicKey: 'tunQheuPpHhjjsbrUDp4rikqYez9UXv4SXLRHf9Kzsv',
      },
    },
  },
  dev: {
    iframeBaseUrl: 'https://passv2-dev.civic.com',
    passConfigs: {
      uniqueness: {
        gatekeeperNetworkPublicKey: 'tunQheuPpHhjjsbrUDp4rikqYez9UXv4SXLRHf9Kzsv',
      },
    },
  },
  preprod: {
    iframeBaseUrl: 'https://passv2-preprod.civic.com',
    passConfigs: {
      uniqueness: {
        gatekeeperNetworkPublicKey: 'tunQheuPpHhjjsbrUDp4rikqYez9UXv4SXLRHf9Kzsv',
      },
    },
  },
  prod: {
    iframeBaseUrl: 'https://passv2.civic.com',
    passConfigs: {
      uniqueness: {
        gatekeeperNetworkPublicKey: 'uniqobk8oGh4XBLMqM68K8M2zNu3CdYX7q5go7whQiv',
      },
    },
  },
  'prod-devnet': {
    iframeBaseUrl: 'https://passv2.civic.com',
    passConfigs: {
      uniqueness: {
        gatekeeperNetworkPublicKey: 'uniqobk8oGh4XBLMqM68K8M2zNu3CdYX7q5go7whQiv',
      },
    },
  },
};

export type PortalConfig = Omit<PortalConfigs, 'passConfigs'> &
  PassConfig & {
    i18nContext: PassType;
  };

function getPortalConfig(passType: PassType = 'uniqueness'): PortalConfig {
  const stage = process.env.REACT_APP_STAGE || 'dev';
  const config = R.propOr<PortalConfigs, Record<string, PortalConfigs>, PortalConfigs>(
    portalConfigs.dev,
    stage,
    portalConfigs
  );
  const partialConfig = R.dissoc('passConfigs', config);
  return {
    ...partialConfig,
    gatekeeperNetworkPublicKey: config.passConfigs[passType].gatekeeperNetworkPublicKey,
    i18nContext: passType,
  };
}

export { getPortalConfig };
