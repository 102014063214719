import React from 'react';
import { Route, BrowserRouter, Redirect } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import UniquenessRevokeScreen from './screens/UniquenessRevokeScreen';

export default function App(): JSX.Element {
  return (
    <span>
      <BrowserRouter>
        <>
          {/* by default, redirect to uniqueness as it is the unique supported Pass type */}
          <Route path="/" exact render={() => <Redirect to="/uniqueness" />} />
          <Route path="/uniqueness" exact component={UniquenessRevokeScreen} />
        </>
      </BrowserRouter>
      <Toaster position="bottom-left" reverseOrder={false} />
    </span>
  );
}
