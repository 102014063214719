import React, { ReactChild } from 'react';
import { useTranslation } from 'react-i18next';
import SVGCivicLogo from '../svg/SVGCivicLogo';

interface ChildProps {
  children: ReactChild | ReactChild[];
}

export default function MainContainer({ children }: ChildProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="main-container p-5 z-1 relative bg-gray-50">
      <div className="top-panel mb-5">
        <div className="top-panel-main-logo">
          <div>
            <div className="px-4 full:px-7 mt-4 flex flex-col items-center">
              <div className="items-center flex flex-row items-center pb-2">
                <SVGCivicLogo />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="min-h-[600px] full:min-h-[600px] full:px-7 absolute-center main-panel mb-10 relative">
        {' '}
        {children}{' '}
      </div>
      <div className="textFooter text-center">
        <span className="mt-4">{t('copyright')}</span>
      </div>
    </div>
  );
}
